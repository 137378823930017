import React, { useState } from "react";
import { Link } from "react-router-dom";
import Teams from "../../api/Teams";
import shape3 from "../../images/background/pattern-5.png";
import { Dialog, DialogContent } from "@mui/material";

const Speakers = (props) => {
  const [open, setOpen] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState({});

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  const selectDoctor = (team) => {
    setSelectedDoctor(team);
    setOpen(!open);
  };
  return (
    <>
    <section className="speakers-one">
      <div className="auto-container1">
        <div className="sec-title title-anim centered">
          
          <h2 id="section1" className="sec-title_heading">Nuestros educadores - AFMCP</h2>
        </div>
        <div className="row clearfix centered-speakers">
          {Teams.slice(0, 9).map((team, aitem) => (
            <div
              className="speaker-block_one col-lg-3 col-md-6 col-sm-12"
              key={aitem}
            >
              <div  onClick={() => selectDoctor(team)} className="speaker-block_one-inner">
                
                
                <div
                  onClick={() => selectDoctor(team)}
                  className="speaker-block_one-image"
                >
                  <img src={team.tImg} alt="" />
                  <div className="speaker-block_one-pattern">
                    <img className="vibe-speakers" src={shape3} alt="" />
                  </div>
                  <div className="speaker-block_one-social">
                    <a onClick={() => selectDoctor(team)} alt="">
                      <span className="speaker-block_one-share fa fa-solid fa-plus"></span>
                    </a>
                  </div>
                </div>
                <div className="speaker-block_one-content">
                  <h4 className="speaker-one_title">
                    <img src={team.flag} alt="" />
                    <Link
                      onClick={ClickHandler}
                      to='#'
                    >
                    </Link>
                      {team.name}
                  </h4>
                  <div className="speaker-one_detail">{team.title}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>


      <Dialog open={open}>
        <DialogContent>
          <div className="newsletter-popup-area-section-speakers">
            <div className="newsletter-popup-area1">
              <div className="newsletter-popup-ineer">
                <div onClick={() => setOpen(false)} className="detail-box">
                  <img
                    className="popup-mobile"
                    src={selectedDoctor.popupM}
                    alt=""
                  />
                  <img
                    className="popup-desktop"
                    src={selectedDoctor.popup}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </section>

{/* section 2 diplomado */}

    <section className="speakers-one">
      <div className="auto-container2">
        <div className="sec-title title-anim centered">
          
          <h2 className="sec-title_heading">Nuestros educadores - Diplomado AMF</h2>
        </div>
        <div className="row clearfix centered-speakers">
          {Teams.slice(9, 17).map((team, aitem) => (
            <div
              className="speaker-block_one col-lg-3 col-md-6 col-sm-12"
              key={aitem}
            >
              <div  onClick={() => selectDoctor(team)} className="speaker-block_one-inner">
                <div
                  onClick={() => selectDoctor(team)}
                  className="speaker-block_one-image"
                >
                  <img src={team.tImg} alt="" />
                  <div className="speaker-block_one-pattern">
                    <img className="vibe-speakers" src={shape3} alt="" />
                  </div>
                  <div className="speaker-block_one-social">
                    <a onClick={() => selectDoctor(team)}>
                      <span className="speaker-block_one-share fa fa-solid fa-plus"></span>
                    </a>
                  </div>
                </div>
                <div className="speaker-block_one-content">
                  <h4 className="speaker-one_title">
                    <img src={team.flag} alt="" />
                    <Link
                      onClick={ClickHandler}
                      to='#'
                    >
                      {team.name}
                    </Link>
                  </h4>
                  <div className="speaker-one_detail">{team.title}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>


      <Dialog open={open}>
        <DialogContent>
          <div className="newsletter-popup-area-section-speakers">
            <div className="newsletter-popup-area1">
              <div className="newsletter-popup-ineer">
                <div onClick={() => setOpen(false)} className="detail-box">
                  <img
                    className="popup-mobile"
                    src={selectedDoctor.popupM}
                    alt=""
                  />
                  <img
                    className="popup-desktop"
                    src={selectedDoctor.popup}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </section>
    </>
  );
};

export default Speakers;
