import { useEffect, useState } from 'react'

const useResponsiveLink = () => {
const [deviceLink, setDeviceLink] = useState('https://www.canva.com/design/DAF2x2dbsjg/x49wGEiXTzRYfyJsu96dZw/view?utm_content=DAF2x2dbsjg&utm_campaign=designshare&utm_medium=link&utm_source=editor')
    useEffect(() => {
        const checkDeviceSize = () => {
            const width = window.innerWidth;
            if (width > 1024) {
                setDeviceLink('https://www.canva.com/design/DAGWjjRd2FA/d17fOpbAguXSNj8SLj5nxQ/view?utm_content=DAGWjjRd2FA&utm_campaign=designshare&utm_medium=link&utm_source=editor')
            }
            else if (width > 768) {
                setDeviceLink('https://www.canva.com/design/DAF3VEONrEo/kWXKMqzkFQRBuZ5M3IktyA/view?utm_content=DAF3VEONrEo&utm_campaign=designshare&utm_medium=link&utm_source=editor')
            }
            else {
                setDeviceLink('https://www.canva.com/design/DAF3VEONrEo/kWXKMqzkFQRBuZ5M3IktyA/view?utm_content=DAF3VEONrEo&utm_campaign=designshare&utm_medium=link&utm_source=editor')
            }
        }
        checkDeviceSize()
        window.addEventListener('resize', checkDeviceSize)

        return () => {
            window.removeEventListener('resize', checkDeviceSize)
        }


    }, [])

    return deviceLink

}

export default useResponsiveLink