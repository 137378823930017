import React from "react";


const Prueba = () => {
  const videoURL = 'https://s3-amf.s3.us-east-1.amazonaws.com/Landing-AMF/videos/Video+Diplomado+AMF+2025_Sin+audio.mp4'

  return (
    <div className="container-video">
      <div className="overlay" />
      {/* <a
        href="https://edu.academiademedicinafuncional.info/producto/diplomado-medicina-funcional-2024-afmcp/"
        target="_blank"
        rel="noreferrer"
        class="btn-hero"
      >Inscríbete acá</a>  */}
      
      <video className="video-hero" src={videoURL} autoPlay loop muted></video>
      
    </div>
  );
};

export default Prueba;
