import React from 'react'
import calendar from '../../calendar.png'
import calendar1 from '../../calendar1.png'
import { Link } from 'react-router-dom'

const Calendar = () => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
  return (
    <div className="container-calendar-wrap">
    <div className='container-calendar'>
        <img className='calendar' src={calendar} alt='7 pilares desktop imagen' />
        <img className='calendar1' src={calendar1} alt='7 pilares mobile imagen' /> 
        {/* <a class="btn-band-variant2" href="https://www.canva.com/design/DAF2x2dbsjg/dboNlTcXdEyDuuplk2kiTA/view" target="_blank" rel="noreferrer">Ver pensum</a> */}
    </div>
    </div>
  )
}

export default Calendar