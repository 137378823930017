import React from "react";

const Hubspot = () => {
  return (
    <>
      <div id="section4" className="hubspotContainer">
        <a
          href="https://share.hsforms.com/1CkER_2sTRbKHQWr_0kxFrwe92zh"
          target="_blank"
          rel="noopener noreferrer"
          className="btn-hubspot"
        >
         Si deseas más información, déjanos tus datos
        </a>
      </div>
    </>
  );
};

export default Hubspot;
